export let SERVER_URL_LIVE = 'https://api.igiobooking.com';

// export let SERVER_URL_LIVE = "http://elinklondon-001-site4.dtempurl.com";
// export let SERVER_URL_LIVE = "http://igio.uksouth.cloudapp.azure.com";
// export let SERVER_URL_LOCAL = "https://api.igiobooking.com";

export let SERVER_URL_LOCAL = 'http://localhost:5000';
// export let SERVER_URL_LOCAL = 'https://api.igiobooking.com';
// tslint:disable-next-line:variable-name
export let google_Android_APIKey =  'AIzaSyC0ya494gmTcLAfFiHL_noKd677S9_9nho';
// tslint:disable-next-line:variable-name
export let google_javascript_APIKey =  'AIzaSyBTxOGkMFs50Np2KBtpbMT2DJtm5P4zaOo';
// tslint:disable-next-line:variable-name
export let FCM_Server_APIKEY = 'AAAAegyxW8M:APA91bG7bEhGyaSULvDxI3q2Zc19GI57VoZwraSNazJldWnjkif2uGejx0VmStFTMpHMGo86o5_W0wsDN7Hp-8YY97L9-UJd5UKHOvRe19cfIjSD5ECcamBnaRy9kQo6lXG4S46gxUHq';

// ionic config set -g proxy