import { Injectable } from '@angular/core';
import { SERVER_URL_LOCAL, SERVER_URL_LIVE, FCM_Server_APIKEY } from './config';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { Location } from '../models/Location';
import { IgioUserDetails } from '../models/IgioUserDetails';
import { UserNotificationSetting } from '../models/UserNotificationSetting';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class IgioUserService {

    // tslint:disable-next-line:variable-name
    public server_url: string = SERVER_URL_LOCAL;
    public userUrl: string;// = SERVER_URL + "/api/IgioEvents";
    public authUrl: string;
    private bookingUrl: string;
    private paymentUrl: string;


    constructor(public _http: HttpClient, public platform: Platform) {
        if (this.platform.is('cordova' || 'capacitor')) {
            console.log('is core');
            this.server_url = SERVER_URL_LIVE;
        }
        this.userUrl = this.server_url + '/api/IgioUser';
        this.authUrl = this.server_url + '/api/Auth';
        this.bookingUrl = this.server_url + '/api/IgioBooking';
        this.paymentUrl = this.server_url + '/api/Payment';
    }


    getIgioUser(emailAddress: string): Observable<any> {
        const getUrl = this.userUrl + '/' + emailAddress;
        return this._http.get(getUrl);
    }

    sendVerifyEmail(userId: string): Observable<any> {
        const getUrl = this.userUrl + '/ResendVerifyEmail/' + userId;
        return this._http.get(getUrl);
    }

    sendResetPasswordEmail(email: string): Observable<any> {
        const getUrl = this.userUrl + '/ResetPasswordEmail/' + email;
        return this._http.get(getUrl);
    }

    verifyTokenId(tokenId: string, userId: string) {
        return this._http.post(this.authUrl + '/VerifyIdToken/' + userId, JSON.stringify(tokenId), httpOptions);
    }

    likeIgioEventShop(userId: string, eventDetailId: string): Observable<any> {
        return this._http.post(this.userUrl + '/LikeIgioEvent/' + eventDetailId + '/' + userId, {}, httpOptions);
    }

    rateIgioEventShop(userId: string,
        eventDetailId: string,
        score: number,
        comment: string,
        tag: string = '',
        reviewImages?: Array<string>): Observable<any> {
        const body = JSON.stringify(reviewImages);
        console.log('body', body);

        return this._http.post(this.userUrl + '/RateIgioEvent/'
            + eventDetailId + '/' + userId + '/' + score + '/' + comment + '/' + tag, body, httpOptions);
    }


    getIgioEventRating(eventDetailId: string) {
        const getUrl = this.userUrl + '/getIgioEventRating/' + eventDetailId;
        return this._http.get(getUrl);
    }

    canRateIgioEventShop(userId: string, eventDetailId: string): Observable<any> {
        const getUrl = this.userUrl + '/CanRateIgioEvent/' + eventDetailId + '/' + userId;
        return this._http.get(getUrl);
    }

    changePassword(password: string, id: string) {

        const body = JSON.stringify(password);
        return this._http.post(this.userUrl + id + '/ChangePassword', body, httpOptions);
    }

    validateCredential(password: string, emailAddress: string) {

        const body = JSON.stringify(password);
        return this._http.post(this.authUrl + '/ValidateCredential/' + emailAddress, body, httpOptions);

    }

    commentIgioEventShop(userId: string, igioEventId: string, comment: string, replyToId?: string, replyUserId?: string): Observable<any> {

        const body = JSON.stringify({
            igioEventId,
            comment,
            replyToId,
            replyUserId
        });
        return this._http.post(this.userUrl + '/CommentIgioEvent/' + userId, body, httpOptions);
    }

    getIgioEventComment(igioEventId: string, userId: string, take: number, skip: number) {
        const getUrl = this.userUrl + '/GetIgioEventComment/' + igioEventId + '/' + userId + '/' + take + '/' + skip;
        return this._http.get(getUrl);
    }

    getIgioUserBookingCount(userId: string) {
        const getUrl = this.userUrl + '/getIgioBookingCount/' + userId;
        return this._http.get<number>(getUrl);
    }

    getIgioCommentCount(userId: string) {
        const getUrl = this.userUrl + '/getIgioCommentCount/' + userId;
        return this._http.get<number>(getUrl);
    }

    getMyIgioBookings(userId: string, status: string, take: number, skip: number) {
        const getUrl = this.userUrl + '/getMyIgioBookings/' + userId + '/' + status + '/' + take + '/' + skip;
        return this._http.get(getUrl);
    }

    getIgioTicketGroups(eventId: string) {
        const getUrl = this.userUrl + '/getIgioTicketGroups/' + eventId;
        return this._http.get(getUrl);
    }

    getMyIgioTickets(eventId: string, userId: string, socialEventId: string,
        take: number, skip: number, status: string = '', date: string = '') {
        const body = JSON.stringify({
            igioEventId: eventId,
            userId,
            socialEventId,
            take,
            skip,
            status,
            date
        });
        return this._http.post(this.userUrl + '/getMyIgioTickets', body, httpOptions);
    }

    getMyIgioBookingsByEventId(eventId: string, inProgress: boolean, completed: boolean,
        cancelled: boolean, returnServices: boolean, take: number, skip: number) {
        const getUrl = this.userUrl + '/getMyIgioBookingsByEventId/' + eventId + '/' + inProgress + '/'
            + completed + '/' + cancelled + '/' + returnServices + '/' + take + '/' + skip;
        return this._http.get(getUrl);
    }

    getIgioClientList(eventId: string): any {
        const getUrl = this.userUrl + '/getIgioClientList/' + eventId;
        return this._http.get(getUrl);
    }

    completeBooking(bookingId: string): Observable<any> {
        const putUrl = this.bookingUrl + '/completeBooking/' + bookingId;
        return this._http.put(putUrl, {}, httpOptions);
    }

    cancelBooking(bookingId: string, refundPayment: boolean, comment: string): Observable<any> {
        const putUrl = this.bookingUrl + '/cancelBooking/' + bookingId + '/' + refundPayment;
        const body = JSON.stringify(comment);
        return this._http.put(putUrl, body, httpOptions);
    }

    cancelTicket(orderId: string, refundPayment: boolean, comment: string): Observable<any> {
        const putUrl = this.bookingUrl + '/cancelTicket/' + orderId + '/' + refundPayment;
        const body = JSON.stringify(comment);
        return this._http.put(putUrl, body, httpOptions);
    }

    refundPayment(igioId: string, isIgioBooking: boolean) {
        const putUrl = this.paymentUrl + '/RefundPayment';
        const body = JSON.stringify({ igioId, isIgioBooking });
        return this._http.put(putUrl, body, httpOptions);
    }

    markBookingAppointmentAsOpen(bookingId: string): Observable<any> {
        const putUrl = this.bookingUrl + '/markBookingAppointmentAsOpen/' + bookingId;
        return this._http.put(putUrl, {}, httpOptions);
    }

    markCommentsAsRead(ids: Array<string>) {
        const putUrl = this.userUrl + '/markCommentAsRead/';
        const body = JSON.stringify(ids);
        return this._http.put(putUrl, body, httpOptions);
    }

    rescheduleBooking(bookingId: string, bookTime: Date): Observable<any> {
        const putUrl = this.bookingUrl + '/RescheduleBooking/' + bookingId;
        const body = JSON.stringify(bookTime);
        return this._http.put(putUrl, body, httpOptions);
    }

    addIgioUser(user: IgioUserDetails): Observable<any> {
        const body = JSON.stringify(user);
        return this._http.post(this.userUrl, body, httpOptions);
    }

    deleteIgioUser(userId: string): Observable<any> {
        return this._http.delete(this.userUrl + '/' + userId, httpOptions);
    }

    updateIgioUser(userId: string, userDetail: IgioUserDetails): Observable<any> {
        throw new Error('Method not implemented.');
    }

    updateIgioUserImage(userId: string, image: any): Observable<any> {
        const imageData = image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
        const body = JSON.stringify(imageData);
        return this._http.post(this.userUrl + '/UploadUserImage/' + userId, body, httpOptions);
    }

    updateAddressById(address: Location): Observable<any> {
        const body = JSON.stringify(address);
        return this._http.put(this.userUrl + '/updateAddressById/' + address.id, body, httpOptions);
    }

    updateUserDetails(userDetail: IgioUserDetails): Observable<any> {
        const body = JSON.stringify(userDetail);
        return this._http.put(this.userUrl + '/updateUserDetails/' + userDetail.userId, body, httpOptions);
    }

    validateUserCredentials(email: string, password: string) {
        const body = JSON.stringify({ email, password });
        return this._http.post(this.userUrl + '/validateUserCredentials', body, httpOptions).pipe(delay(1000), map(res => res));
    }

    validateEmailNotTaken(email: string) {
        const body = JSON.stringify(email);
        return this._http.post(this.userUrl + '/validateEmailNotTaken', body, httpOptions).pipe(delay(2000), map(res => res));
    }


    updateUserTokens(userId: string, deviceTokens: string): Observable<any> {
        console.log('deviceTokens:', deviceTokens);
        const body = JSON.stringify(deviceTokens);
        return this._http.put(this.userUrl + '/updateUserTokens/' + userId, body, httpOptions);
    }

    getUserNotificationSetting(userId: string) {
        const getUrl = this.userUrl + '/getUserNotificationSetting/' + userId;
        return this._http.get(getUrl);
    }

    getUserNotificationEventService(userId: string) {
        const getUrl = this.userUrl + '/getUserNotificationEventService/' + userId;
        return this._http.get(getUrl);
    }

    setUserNotificationSetting(userId: string, userNotification: UserNotificationSetting) {
        const body = JSON.stringify(userNotification);
        return this._http.put(this.userUrl + '/setUserNotificationSetting/' + userId, body, httpOptions);
    }

    setUserNotificationForServices(userId: string, services: string[], radius: string) {
        const body = JSON.stringify(services);
        return this._http.put(this.userUrl + '/setUserNotificationEventService/' + userId + '/' + radius, body, httpOptions);
    }

    notifyEventOwner(eventId: string, userId: string, message: string, type: string) {
        this.getEventUserToken(eventId, userId).subscribe((data: any) => {
            if (data) {
                const pushOptions = {
                    notification: {
                        title: 'Igio Activities',
                        body: message,
                        sound: 'default',
                        click_action: 'FCM_PLUGIN_ACTIVITY',
                        icon: 'fcm_push_icon'
                    }, data: {
                        type
                    },
                    to: data.eventUserToken,
                    priority: 'high',
                    restricted_package_name: ''
                };

                if (data.eventUserToken) {
                    this.postPushNotification(pushOptions, data.userToken).subscribe(result => {
                        console.log('notification was created successfully');
                    }, err => {
                        console.log('error occured sending notification', err);
                    });
                }
            }
        });
    }

    sendTopicNotification(eventId: string, userId: string, topic: string, message: string) {
        this.getEventUserToken(eventId, userId).subscribe((data) => {
            if (data) {
                const body = {
                    notification: {
                        title: 'New Notification has arrived',
                        body: 'Notification Body',
                        sound: 'default',
                        click_action: 'FCM_PLUGIN_ACTIVITY',
                        icon: 'fcm_push_icon'
                    },
                    data: {
                        param1: 'value1',
                        param2: 'value2'
                    },
                    to: '/topics/all',
                    priority: 'high',
                    restricted_package_name: ''
                }

                const options = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: 'key=' + FCM_Server_APIKEY
                    })
                };

                const postUrl = 'https://fcm.googleapis.com/fcm/send';
                this._http.post(postUrl, body, options).subscribe();
                // .pipe(map((response: Response) => response)).subscribe();
            }
        });

    }

    private postPushNotification(pushOptions: any, senderToken) {
        const body = pushOptions;
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'key=' + FCM_Server_APIKEY
            })
        };

        const postUrl = 'https://fcm.googleapis.com/fcm/send';
        return this._http.post(postUrl, body, options);
    }

    private getEventUserToken(eventId: string, userId: string) {
        const getUrl = this.userUrl + '/getGetToken/' + eventId + '/' + userId;
        return this._http.get(getUrl);
    }
}
