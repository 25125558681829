import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthProviderService } from './auth-provider.service';
import { NavController } from '@ionic/angular';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private auth: AuthProviderService, private navCtrl: NavController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            if (err.status === 401) {
                if(err.url.startsWith('http://localhost') || err.url.startsWith('https://api.igiobooking.com')){
                    // auto logout if 401 response returned from api
                    this.auth.logoutUser();
                    this.navCtrl.navigateRoot(['login']);
                }
            }

            const error = err.error?.message || err.statusText;
            return throwError(error);
        }))
    }
}