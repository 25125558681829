import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IgioUserDetails } from 'src/app/models/IgioUserDetails';
import { AuthProviderService } from '../auth-provider.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  // public updatePictureObserver: Observable<any>;
  // public pictureObserver: any;
  // public loadUserDetailsObserver: Observable<any>;
  // public userEmailObserver: any;
  initiateUserDetailLoading$: BehaviorSubject<boolean>;


  constructor(private authProvider: AuthProviderService) {
    this._userDetails = new IgioUserDetails();
    this.initiateUserDetailLoading$ = new BehaviorSubject(false);

    // this.pictureObserver = null;
    // this.userEmailObserver = null;
    // this.updatePictureObserver = Observable.create(observer => {
      // this.pictureObserver = observer;
    // });

    // this.loadUserDetailsObserver = Observable.create(observer => {
    //   this.userEmailObserver = observer;
    // });
  }

  private _userDetails: IgioUserDetails;
  public get userDetails(): IgioUserDetails {
    return this._userDetails;
  }
  public set userDetails(v: IgioUserDetails) {
    this._userDetails = v;
  }

  private _isNew: boolean;
  public get isNew(): boolean {
    return this._isNew;
  }
  public set isNew(v: boolean) {
    this._isNew = v;
  }

  private _stripePublishableKey: string;
  public get stripePublishableKey(): string {
    return this._stripePublishableKey;
  }
  public set stripePublishableKey(v: string) {
    this._stripePublishableKey = v;
  }


  private _igioEventId: string;
  public get igioEventId(): string {
    return this._igioEventId;
  }
  public set igioEventId(v: string) {
    this._igioEventId = v;
  }


  private _socialEventId: string;
  public get socialEventId(): string {
    return this._socialEventId;
  }
  public set socialEventId(v: string) {
    this._socialEventId = v;
  }



  private _igioDetailId: string;
  public get igioDetailId(): string {
    return this._igioDetailId;
  }
  public set igioDetailId(v: string) {
    this._igioDetailId = v;
  }


  private _countryCode: string;
  public get countryCode(): string {
    return this._countryCode;
  }
  public set countryCode(v: string) {
    this._countryCode = v;
  }


  private activeAppointmentNotifier: Subject<number> = new Subject<number>();
  activeAppointmentNotifier$ = this.activeAppointmentNotifier.asObservable();
  private _activeAppointments: number;
  public get activeAppointments(): number {
    return this._activeAppointments;
  }
  public set activeAppointments(v: number) {
    this.activeAppointmentNotifier.next(v)
    this._activeAppointments = v;
  }


  private _unreadCommentCount: number;
  public get unreadCommentCount(): number {
    return this._unreadCommentCount;
  }
  public set unreadCommentCount(v: number) {
    this._unreadCommentCount = v;
  }



  private _hasRegisteredBusiness: boolean;
  public get hasRegisteredBusiness(): boolean {
    return this._hasRegisteredBusiness;
  }
  public set hasRegisteredBusiness(v: boolean) {
    this._hasRegisteredBusiness = v;
  }

  private _userId: string;
  public get userId(): string {
    return this._userId;
  }
  public set userId(v: string) {
    this._userId = v;
  }


  private _dataLoaded: boolean;
  public get dataLoaded(): boolean {
    return this._dataLoaded;
  }
  public set dataLoaded(v: boolean) {
    this._dataLoaded = v;
  }


  private _isEmailVerified: boolean;
  public get isEmailVerified(): boolean {
    return this._isEmailVerified;
  }
  public set isEmailVerified(v: boolean) {
    this._isEmailVerified = v;
  }



  private _ratingAverage: number;
  public get ratingAverage(): number {
    return this._ratingAverage;
  }
  public set ratingAverage(v: number) {
    this._ratingAverage = v;
  }


  private _ratingCount: number;
  public get ratingCount(): number {
    return this._ratingCount;
  }
  public set ratingCount(v: number) {
    this._ratingCount = v;
  }


  private _authToken: string;
  public get authToken(): string {
    // if(!this._authToken){
    // this._authToken = await this.authProvider.getAuthToken();
    // }
    return this._authToken;
  }
  public set authToken(v: string) {
    this._authToken = v;
  }


  private _dateCreated: string;
  public get dateCreated(): string {
    return this._dateCreated;
  }
  public set dateCreated(v: string) {
    this._dateCreated = v;
  }


  public clearData() {
    this._userDetails = new IgioUserDetails();
    this._userId = null;
    this._igioEventId = null;
    this._ratingCount = null;
    this._igioDetailId = null;
    this._ratingAverage = null;
    this._hasRegisteredBusiness = null;
    this._authToken = null;
    this._dateCreated = null;
    this._activeAppointments = null;
    this._dataLoaded = null;
    this._isEmailVerified = null;

  }

}
