import { Component, NgZone, ViewEncapsulation } from '@angular/core';

import { Platform, MenuController, ToastController, ModalController, NavController } from '@ionic/angular';
import { IgioUserService } from './services/igio-user.service';
import { Events } from './services/imported/events';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { UserDataService } from './services/user/user-data.service';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { CountriesPage } from './countries/countries.page';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  StatusBarStyle,
  Capacitor
} from '@capacitor/core';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { NavigationError, NavigationStart, Router } from '@angular/router';
const { PushNotifications, StatusBar, App, SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  rootPage: string;
  pages: Array<{ title: string, icon?: string, url?: string, isGroupHeader?: boolean, visible?: boolean }>
  displayName: string;
  hasRegisteredBusiness: boolean;
  anonymousUser = false;
  deviceToken: any;
  isViaDeepLink = false;
  centerContent: boolean;
  tabsParams = {
    subscribetabs: null
  };
  isPWA = false;
  hideLogo = false;
  isNavigatingToUrl = false;

  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private igioUserProvider: IgioUserService,
    public events: Events,
    public menuCtrl: MenuController,
    public afAuth: AngularFireAuth,
    public shareUserData: UserDataService,
    public toastCtrl: ToastController,
    public appRate: AppRate,
    public globalization: Globalization,
    public modalCtrl: ModalController,
    public inAppBrowser: InAppBrowser,
    public zone: NgZone,
    private router: Router) {
    this.isPWA = this.platform.is('desktop') || this.platform.is('pwa');
    this.initializeApp();
    if (this.isPWA) {
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          console.log(event.url);
          if (event.url.startsWith('/login')) {
            this.centerContent = false;
          } else {
            this.centerContent = true;
          }

          if (event.url.startsWith('/igio-detail')) {
            this.isNavigatingToUrl = true;
          } else if (event.url.startsWith('/igio-social-ticket')) {
            this.isNavigatingToUrl = true;
          }
        }
      });
    }
  }


  initializeApp() {
    this.platform.ready().then(async () => {
      this.afAuth.authState.subscribe(async user => {
        if (user) {
          let newUserId = '';
          if (this.shareUserData.isNew && !user.displayName) {
            newUserId = this.shareUserData.userDetails.userId;
          }
          const tokenId = await user.getIdToken();
          this.igioUserProvider.verifyTokenId(tokenId, newUserId).subscribe(async (token: any) => {
            this.shareUserData.authToken = token.authtoken;
            this.anonymousUser = false;

            if (!this.shareUserData.isNew) {
              await this.updateAppMenuAndLoadTabs();
              SplashScreen.hide();
            }
          });
        } else {
          SplashScreen.hide();
          await this.updateAppMenuAndLoadTabs('login');
          this.anonymousUser = true;
        }
      }, error => {
        this.navCtrl.navigateRoot(['login']);
        console.log('authObserver:----', error);
      });

      try {
        this.initializeFirebase();
      } catch (error) {
        alert(JSON.stringify('error initializing push notification ' + error));
      }

      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      const onDevice = this.platform.is('cordova' || 'capacitor');
      if (onDevice) {
        // try {
        StatusBar.setStyle({ style: StatusBarStyle.Dark });
        StatusBar.setBackgroundColor({ color: '#464c6f' });
        // } catch (error) {
        //   alert(JSON.stringify(error));
        // }

        this.setupDeeplink();

        this.globalization.getPreferredLanguage()
          .then(res => {
            if (res.value === 'en-US') {
              this.shareUserData.countryCode = 'en-GB';
            } else {
              this.shareUserData.countryCode = res.value;
            }
          })
          .catch(() => {
            // alert("We can not determine your location. We are setting your default country as UK");
            this.shareUserData.countryCode = 'en-GB';
          });
      } else {
        this.shareUserData.countryCode = 'en-GB';
      }

      this.events.subscribe('registerUserAndLoadTabs', async () => {
        this.anonymousUser = false;
        await this.updateAppMenuAndLoadTabs();
      });

      this.events.subscribe('registerAnonymousUserAndLoadTabs', async () => {
        this.anonymousUser = true;
        if (!this.shareUserData.countryCode) {
          const countryModel = await this.modalCtrl.create({ component: CountriesPage });
          countryModel.onDidDismiss().then(async data => {
            if (data) {
              await this.updateAppMenuAndLoadTabs();
            }
          });
          countryModel.present();
        } else {
          await this.updateAppMenuAndLoadTabs();
        }
      });
      this.rateIgioBookingApp(false);
    });
  }

  setupDeeplink() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const paths = data.url.split('.com/').pop();
        let internalPath;
        let navOption: NavigationOptions = null;
        if (paths.split('/').length > 1) {
          internalPath = paths.split('/')[0];
          navOption = { queryParams: { id: paths.split('/')[1] } }
        } else {
          internalPath = paths.split('/').pop();
        }

        if (internalPath) {
          this.navCtrl.navigateRoot([internalPath], navOption);
        }
      });
    });
  }

  async updateAppMenuAndLoadTabs(rootPage: string = 'tabs') {
    const currentUser = await this.afAuth.currentUser
    if (currentUser != null) {
      this.shareUserData.userDetails.emailAddress = currentUser.email;
      const userId = currentUser.displayName;
      this.shareUserData.userId = userId;
      this.shareUserData.initiateUserDetailLoading$.next(true);
      if (this.deviceToken && userId) {
        this.updateUserToken(this.deviceToken, userId);
      }

      this.pages = [
        { title: 'Links', isGroupHeader: true, icon: 'link-sharp' },
        { title: 'Love Igio Bookings', icon: 'happy-sharp', url: 'rate-us', visible: true },
        { title: 'Invite Friends', icon: 'people-sharp', url: 'invite-friends', visible: true },
        { title: 'Feedback', icon: 'medal-sharp', url: 'feedback', visible: true },
        { title: 'Terms and Conditions', icon: 'newspaper-sharp', url: 'terms-and-condition', visible: true },
        { title: 'Privacy', icon: 'eye-off-sharp', url: 'privacy', visible: true },
        { title: 'Sign off', icon: 'log-out-sharp', url: '', visible: true }
      ];

      // using photoURL to store the account type
      if (currentUser.photoURL === '2') {
        this.addSwicthModeOptions();
        if (!this.isViaDeepLink) {
          this.navCtrl.navigateRoot(['admin-page'])
        }
        return;
      }
    } else {
      this.pages = this.getAnonymousMenu()
    }

    this.rootPage = rootPage;


    if (!this.isViaDeepLink && !this.anonymousUser && !this.isNavigatingToUrl) {
      this.navCtrl.navigateRoot([this.rootPage]);
    }
  }
  getAnonymousMenu(): { title: string; icon?: string; url?: string; isGroupHeader?: boolean; visible?: boolean; }[] {
    return [
      { title: 'Links', isGroupHeader: true },
      { title: 'Love Igio Bookings', icon: 'happy-sharp', url: 'rate-us', visible: true },
      { title: 'Invite Friends', icon: 'people-sharp', url: 'invite-friends', visible: true },
      { title: 'Terms and Conditions', icon: 'newspaper-sharp', url: 'terms-and-condition', visible: true },
      { title: 'Privacy', icon: 'eye-off-sharp', url: 'privacy', visible: true },
      { title: 'Register / Sign In', icon: 'log-in-sharp', url: 'login', visible: true }
    ];
  }

  addSwicthModeOptions() {
    const switchMode = [
      { title: 'Switch Mode', isGroupHeader: true },
      { title: 'Business Mode', icon: 'business-sharp', url: 'admin-page', visible: true, selected: true },
      { title: 'Client Mode', icon: 'home-sharp', url: 'tabs', visible: true, selected: false }];

    if (!this.pages.some(x => x.url === 'admin-page')) {
      this.pages = [...switchMode, ...this.pages];
    }

  }


  openPage(page) {
    if (page && page.url) {
      // Reset the content nav to have just this page
      // we wouldn't want the back button to show in this scenario
      if (page.url === 'register') {
        this.navCtrl.navigateForward([page.url], {
          queryParams: {
            editMode: this.hasRegisteredBusiness,
            userId: this.shareUserData.userId, address: this.shareUserData.userDetails.userlocation
          }
        })
      } else if (page.url === 'rate-us') {
        this.rateIgioBookingApp(true);
      } else if (page.url === 'terms-and-condition') {
        this.openUrl('terms');
      } else if (page.url === 'privacy') {
        this.openUrl('privacy');
      } else if (page.url === 'help') {
        this.openUrl('help');
      } else if (page.url === 'admin-page' || page.url === '') {
        if (page.url === 'admin-page') {
          const xx: any = this.pages.filter(x => x.url === '')[0];
          xx.selected = false;
        } else {
          const xx: any = this.pages.filter(x => x.url === 'admin-page')[0];
          xx.selected = false;
        }
        page.selected = true;
        this.navCtrl.navigateRoot([page.url])

      }
      else {
        this.navCtrl.navigateForward([page.url])
      }
    }
    else {
      this.afAuth.signOut();
      this.shareUserData.clearData();
      this.navCtrl.navigateRoot(['login']);
      this.pages = this.getAnonymousMenu();
    }
  }

  rateIgioBookingApp(rateNow: boolean) {
    // or, override the whole preferences object
    this.appRate.preferences = {
      usesUntilPrompt: 9,
      storeAppURL: {
        ios: '833T6RH97U.com.ionicframework.igio562765',
        android: 'market://details?id=com.ionicframework.igio562765'
      }
    };

    if (rateNow) {
      this.appRate.navigateToAppStore();
    } else {
      this.appRate.promptForRating(rateNow);
    }
  }

  updateUserToken(token: any, userId) {
    this.igioUserProvider.updateUserTokens(userId, token).subscribe(() => {
      console.log('token updated');
    }, (err) => {
      alert('error trying to update token' + err);
    });
  }

  viewMyBookings() {
    this.menuCtrl.close();
    this.navCtrl.navigateForward(['view-bookings'])
  }

  viewComments() {
    const userId = this.shareUserData.userDetails.userId;
    const userName = this.shareUserData.userDetails.username;

    this.navCtrl.navigateForward(['chat'], {
      queryParams: {
        userId, userName, igioDetailId: this.shareUserData.igioDetailId,
        igioEventId: this.shareUserData.igioEventId, allowReply: this.hasRegisteredBusiness
      }
    })
    this.menuCtrl.close();
  }

  openUrl(page: string) {
    const url = `https://www.igiobooking.com/${page}`;

    if (url) {
      const options: InAppBrowserOptions = {
        closebuttoncaption: 'Close',
        hideurlbar: 'yes',
        toolbarcolor: '#464c71',
        clearcache: 'yes'
      }

      const browser = this.inAppBrowser.create(url, '_self', options);
      browser.on('loadstop').subscribe(() => {
        browser.insertCSS({ code: '.navbar-top{display: none;' });
      });
    }
  }

  initializeFirebase() {
    if (this.platform.is('cordova' || 'capacitor')) {
      this.subscribeToPushNotifications();
    }
  }

  subscribeToPushNotifications() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        this.deviceToken = token.value;
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        this.presentNotificationToast(notification.body, notification.data);

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        const data: any = notification.notification.data;
        this.notificationNavigateTo(data);
      }
    );

  }

  private notificationNavigateTo(data: any) {
    if (data.IsSocialEvent) {
      const isSoical = JSON.parse(data.IsSocialEvent.toLowerCase());
      if (isSoical) {
        this.navCtrl.navigateForward(['igio-social-ticket'], {
          queryParams: {
            id: data.EventId
          }
        });
      } else if (!isSoical) {
        this.navCtrl.navigateForward(['igio-detail'], {
          queryParams: {
            id: data.EventId
          }
        });
      }
    }
  }

  async presentNotificationToast(msg: any, data: any) {
    let message;
    let header = '';
    let btns = [
      {
        text: 'Close',
        handler: () => {
          // this is a test
        }
      }];

    if (data && data.type) {
      switch (data.type) {
        case 'comment':
          header = 'Comment';
          message = 'You have a new message';
          if (msg) {
            message = msg;
          }
          this.igioUserProvider.getIgioCommentCount(this.shareUserData.userId).subscribe((count) => {
            this.shareUserData.unreadCommentCount = count;
          });
          break;
        case 'booking':
          header = 'Booking';
          message = 'You have a new booking';
          if (msg) {
            message = msg;
          }
          this.igioUserProvider.getIgioUserBookingCount(this.shareUserData.userId).subscribe(count => {
            this.shareUserData.activeAppointments = count;
          });
          break;
        case 'like':
          header = 'Thumbs up';
          message = 'Someone liked your page';
          if (msg) {
            message = msg;
          }
          break;
        case 'unlike':
          message = 'Someone unliked your page';
          break;
        case 'eventNotification':
          message = msg;
          btns = [{
            text: 'View',
            handler: () => {
              this.notificationNavigateTo(data);
            }
          },
          {
            text: 'Close',
            handler: () => { }
          }];
          break;
      }
    } else {
      message = msg;
    }

    if (message) {
      const toast = await this.toastCtrl.create({
        header,
        message,
        duration: 10000,
        position: 'bottom',
        cssClass: 'custom-toast',
        buttons: btns,
        animated: true
      });

      toast.onDidDismiss().then(() => {
        console.log('Dismissed toast');
      });

      toast.present();
    }
  }

  errorOnAvatar() {
    if (this.isPWA) {
      this.shareUserData.userDetails.thumbnailImage = 'assets/home-img/igio-circle-transparent.png';
      this.hideLogo = true;
    }
    else {
      this.shareUserData.userDetails.thumbnailImage = 'assets/user-img/none-picture.png';
      this.hideLogo = false;
    }
  }


}
