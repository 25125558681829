import { IgioAccountTypeEnum } from './IgioAccountTypeEnum';
import { Location } from './Location';
import { ReplaySubject } from 'rxjs';
export class IgioUserDetails {
    public userId = '';
    public firstname: string;
    public lastname: string;
    public mobileno: string;
    public datecreated: Date;
    public emailAddress: string;
    public website: string;
    public username: string;
    public password: string;
    public countryCode: string;
    public accounttype: IgioAccountTypeEnum;
    public userlocation: Location;



    private _thumbnailImage: string;
    public get thumbnailImage(): string {
        return this._thumbnailImage;
    }
    public set thumbnailImage(v: string) {
        this._thumbnailImage = v;
    }

    // private activeEmailAddressNotifier: Subject<string> = new Subject<string>();
    // activeEmailAddressNotifier$ = this.activeEmailAddressNotifier.asObservable();
    // private _emailAddress: string;
    // public get emailAddress(): string {
    //     return this._emailAddress;
    // }
    // public set emailAddress(v: string) {
    //     // this.activeEmailAddressNotifier.next(v);
    //     this._emailAddress = v;
    // }


    constructor(userId?: string,
        firstName?: string,
        lastName?: string,
        mobileNo?: string,
        emailAddress?: string,
        website?: string,
        userName?: string) {

        this.userId = userId;
        this.firstname = firstName;
        this.lastname = lastName;
        this.mobileno = mobileNo;
        this.emailAddress = emailAddress;
        this.website = website;
        this.username = userName;
    }
}
