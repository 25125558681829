
import { CountryEnum, CountryTextEnum, CountryCodeEnum, CountryImageEnum, CountrySymbolEnum } from '../enum/countryEnum';

export class Country {
    constructor(public id: number, public countryName: string,
        public countryCode: string, public countryImage: string, public currencySymbol: string) {
    }

    static getCountries(): Array<Country> {
        const countries = new Array<Country>();
        Object.keys(CountryEnum)
            .forEach(key => {
                if (!isNaN(Number(key))) {
                    countries.push(new this(Number(key), CountryTextEnum[CountryEnum[key]],
                        CountryCodeEnum[CountryEnum[key]], CountryImageEnum[CountryEnum[key]], CountrySymbolEnum[CountryEnum[key]]));
                }
            });
        return countries;
    }

}