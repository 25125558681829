export enum CountryEnum{
    Ireland =0,
    Nigeria =1,
    UK=2
}

export enum CountryTextEnum {
    Ireland = 'Ireland',
    Nigeria = 'Nigeria',
    UK = 'United Kingdom'
}

export enum CountryCodeEnum {
    Ireland = 'en-ie',
    Nigeria ='ng',
    UK = 'en-GB'
}

export enum CountryImageEnum {
    Ireland = 'ie.png',
    Nigeria ='ng.png',
    UK = 'gb.png'
}

export enum CountrySymbolEnum {
    Ireland = 'EUR',
    Nigeria = 'NGN',
    UK = 'GBP'
}

