import { Injectable } from '@angular/core';
import { UserDataService } from './user/user-data.service';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private shareUserData: UserDataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available

    if (this.shareUserData.authToken) {
      if (request.url.startsWith('http://localhost') ||
      request.url.startsWith('https://api.igiobooking.com')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.shareUserData.authToken}`
          }
        });
      }
    }

    return next.handle(request);
  }
}