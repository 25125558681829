import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage';
import { NgCalendarModule } from 'ionic2-calendar';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { CameraService } from './services/utils/camera.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Network } from '@ionic-native/network/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { BasicAuthInterceptor } from './services/interceptor.service';
import { ErrorInterceptor } from './services/error-interception.service';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';


// import { CacheService } from './services/cache.service';

// Initialize Firebase
// let firebaseConfig = {
//   apiKey: 'AIzaSyCId8vmJMs0yLS-uc2P49qKQ4jXSO6JSYQ',
//   authDomain: 'igio-project.firebaseapp.com',
//   databaseURL: 'https://igio-project.firebaseio.com',
//   projectId: 'igio-project',
//   storageBucket: 'igio-project.appspot.com',
//   messagingSenderId: '524198960067'
// };

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgCalendarModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    NgxIonicImageViewerModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    SplashScreen,
    AppRate,
    Globalization,
    InAppBrowser,
    CameraService,
    Diagnostic,
    AppVersion,
    NetworkInterface,
    EmailComposer,
    Network,
    LaunchNavigator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
