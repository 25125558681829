import { Component, OnInit } from '@angular/core';
import { Country } from './model/country';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { Events } from '../services/imported/events';
import { UserDataService } from '../services/user/user-data.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.page.html',
  styleUrls: ['./countries.page.scss'],
})
export class CountriesPage implements OnInit {

  igioCountries = new Array<Country>();
  selectedCountryCode: string;
  constructor(public navCtrl: NavController, public navParams: NavParams,
    private modalCtrl: ModalController,
    public shareUserData: UserDataService, public events: Events) {
  }
  ngOnInit(): void {
    this.igioCountries = Country.getCountries();
    this.selectedCountryCode = this.shareUserData.countryCode;
    console.log('loaded', this.igioCountries);
  }



  goBack() {
    this.modalCtrl.dismiss();
  }

  onCountrySelect(country: Country) {
    console.log(country);
    if (this.shareUserData.countryCode !== country.countryCode) {
      this.events.publish('refreshIgioEvent');
    }
    this.shareUserData.countryCode = country.countryCode;
    this.modalCtrl.dismiss(country);
  }

}
