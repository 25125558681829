import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'admin-page',
    loadChildren: () => import('./admin-page/admin-page.module').then(m => m.AdminPagePageModule)
  },
  {
    path: 'book-my-appointment',
    loadChildren: () => import('./book-my-appointment/book-my-appointment.module').then(m => m.BookMyAppointmentPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'countries',
    loadChildren: () => import('./countries/countries.module').then(m => m.CountriesPageModule)
  },
  {
    path: 'expired',
    loadChildren: () => import('./expired/expired.module').then(m => m.ExpiredPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'igio-detail',
    loadChildren: () => import('./igio-detail/igio-detail.module').then(m => m.IgioDetailPageModule)
  },
  {
    path: 'igio-social-ticket',
    loadChildren: () => import('./igio-social-ticket/igio-social-ticket.module').then(m => m.IgioSocialTicketPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./igio-social-ticket/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'invite-friends',
    loadChildren: () => import('./invite-friends/invite-friends.module').then(m => m.InviteFriendsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./auth/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./bookmarks/bookmarks.module').then(m => m.BookmarksPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'completed',
    loadChildren: () => import('./payment/payment-complete/payment-complete.module').then(m => m.PaymentCompletePageModule)
  },
  {
    path: 'rate-us',
    loadChildren: () => import('./rate-us/rate-us.module').then(m => m.RateUsPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./registerShop/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'schedule-appointment',
    loadChildren: () => import('./schedule-appointment/schedule-appointment.module').then(m => m.ScheduleAppointmentPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'terms-and-condition',
    loadChildren: () => import('./terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionPageModule)
  },
  {
    path: 'images',
    loadChildren: () => import('./upload/images/images.module').then(m => m.ImagesPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./upload/videos/videos.module').then(m => m.VideosPageModule)
  },
  {
    path: 'view-booking',
    loadChildren: () => import('./view-bookings/view-booking/view-booking.module').then(m => m.ViewBookingPageModule)
  },
  {
    path: 'view-bookings',
    loadChildren: () => import('./view-bookings/view-bookings.module').then(m => m.ViewBookingsPageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./user/address/address.module').then(m => m.AddressPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./user/detail/detail.module').then(m => m.DetailPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'account-creation-sucess',
    loadChildren: () => import('./auth/account-creation-sucess/account-creation-sucess.module')
      .then(m => m.AccountCreationSucessPageModule)
  },
  {
    path: 'nearest-to-me',
    loadChildren: () => import('./components/igio-header/nearest-to-me-modal/nearest-to-me-modal.module')
      .then(m => m.NearestToMeModalPageModule)
  },
  { path: '404', redirectTo: '/tabs' },
  { path: '**', redirectTo: '/404' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false }) // preloadingStrategy: PreloadAllModules
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
