import { Injectable } from '@angular/core';
import { Platform, AlertController, NavParams, NavController } from '@ionic/angular';
// import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthProviderService {

  constructor(public afAuth: AngularFireAuth,
    public platform: Platform, public alertCtrl: AlertController, private navCtrl: NavController) { }

  async isAuthenticatedWithMessage() {
    // const loggedOut = this.afAuth.currentUser == null;
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    const isAuth = user != null;
    if (!isAuth) {
      const alert = await this.alertCtrl.create({
        header: 'Sign in to Igio Booking',
        message: `<p> You need to sign in or register to</p>
        <ul>
          <li>Save you favourite searches.</li>
          <li>Ask a question to business before booking.</li>
          <li>Like items</li>
          <li>Book an appointment</li>
        </ul>`,
        cssClass: 'simple-alert',
        buttons: [
          {
            text: 'CANCEL',
            handler: data => {
            }
          },
          {
            text: 'SIGN IN',
            handler: data => {
              // this.nav.push("LoginSliderPage", {slideTo: 2});
              // this.app.getRootNav().push('LoginSliderPage', { needLogin: true });
              this.navCtrl.navigateRoot('/login')
            }
          }
        ]
      });
      await alert.present()
    }
    return isAuth;
  }

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async changePassword(newPassword: string) {
    return (await this.afAuth.currentUser).updatePassword(newPassword);
  }

  logoutUser(): Promise<any> {
    return this.afAuth.signOut();
  }

  signupUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(newEmail, newPassword);
  }

  verifyUserEmail(email: string) {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  loginWithFB() {
    if (this.platform.is('cordova'||'capacitor')) {
      // return this.facebook.login(['email']).then(res => {
      //   const facebookCredential = firebase.auth.FacebookAuthProvider.credential(res.authResponse.accessToken);
      //   firebase.auth().signInWithCredential(facebookCredential).then((fbData) => {
      //     alert(JSON.stringify(fbData));
      //   });
      // });
    }

    // return firebase.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
  }

  async setUserDisplayNameWithId(userId: string, photoURL: string = null) {
    const currentUser = await this.afAuth.currentUser;
    await currentUser.updateProfile({ displayName: userId, photoURL });
    await currentUser.reload();
  }

  async reloadUser(){
    const currentUser = await this.afAuth.currentUser;
    await currentUser.reload();
  }

  async getAuthToken() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    const token = user.getIdToken();
    console.log(token);
    return token;
  }

  async isAuthenticated() {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    return user != null;
  }
}
