import { Injectable } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Plugins, CameraResultType, CameraOptions, CameraSource } from '@capacitor/core';

const { Camera } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  private height = 400;
  private width = 700;
  constructor() {
  }

  getPictureFromCamera(targetWidth?, targetHeight?) {
    return this.takePicture(CameraSource.Camera, targetWidth, targetHeight);
  }

  getPictureFromPhotoLibrary(targetWidth?, targetHeight?) {
    return this.takePicture(CameraSource.Photos, targetWidth, targetHeight);
  }

  async takePicture(pictureSourceType, targetWidth, targetHeight, quality = 90, allowEdit = true, saveToAlbum = false) {

    if (!targetHeight) {
      targetHeight = this.width;
    }

    if (!targetWidth) {
      targetWidth = this.height;
    }

    const options: CameraOptions = {
      quality,
      resultType: CameraResultType.DataUrl,
      source: pictureSourceType,
      allowEditing: allowEdit,
      preserveAspectRatio: true,
      saveToGallery: saveToAlbum,
      correctOrientation: true,
      width: targetWidth,
      height: targetHeight,
    };

    const image = await Camera.getPhoto(options);
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    // const imageData = image.dataUrl;

    const base64Image = image.dataUrl;
    console.log('image data from source', base64Image);
    return base64Image;
  }

  // This method takes optional parameters to make it more customizable
  // getImage(pictureSourceType, crop = true, targetWidth, targetHeight, quality = 90, allowEdit = true, saveToAlbum = false) {
  //   if (!targetHeight) {
  //     targetHeight = this.width;
  //   }

  //   if (!targetWidth) {
  //     targetWidth = this.height;
  //   }

  //   const options = {
  //     quality,
  //     destinationType: this.camera.DestinationType.DATA_URL,
  //     sourceType: pictureSourceType,
  //     allowEdit,
  //     encodingType: this.camera.EncodingType.JPEG,
  //     saveToPhotoAlbum: saveToAlbum,
  //     correctOrientation: true,
  //     targetWidth,
  //     targetHeight
  //   };

  //   // If set to crop, restricts the image to a square of 600 by 600
  //   if (crop) {
  //     options.targetWidth = targetWidth;
  //     options.targetHeight = targetHeight;
  //   }

  //   return this.camera.getPicture(options).then(imageData => {
  //     const base64Image = 'data:image/png;base64,' + imageData;
  //     console.log('image dtat from source', imageData);
  //     return base64Image;
  //   }, error => {
  //     console.log('CAMERA ERROR -> ' + JSON.stringify(error));
  //   });
  // }
}
